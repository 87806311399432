<template>
  <head-panel>
    <template v-slot:body>
      Повідомлення <span v-if="message"> №{{ message.uuid }}</span></template>
  </head-panel>

  <div class="wrapper" >

    <Alert ref="alert"/>

    <form @submit.prevent="submit">

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Назва</div>
        <div class="col-lg-6">
          <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>



      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Статус</div>
        <div class="col-lg-6">
          <select class="form-select" v-model="form.status">
            <option v-for="item in statuses" :key="item" v-bind:value="item.name">{{ item.label }}</option>
          </select>
        </div>
      </div>

      <div class="row ms-2 mb-4" v-if="form.type === 'MAIL'">
        <div class="col-xl-3">Від</div>
        <div class="col-lg-6">
          <input type="text" class="form-control" v-model="form.mailFrom" :class="{ 'is-invalid' : v$.form.mailFrom.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.mailFrom.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4" v-if="form.type === 'MAIL'">
        <div class="col-xl-3">Тема</div>
        <div class="col-lg-6">
          <input type="text" class="form-control" v-model="form.mailSubject">
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-9">
          <textarea class="form-control" v-model="form.body" :class="{ 'is-invalid' : v$.form.body.$error}"/>
          <div class="invalid-feedback" v-for="error of v$.form.body.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Alert from "../../components/Alert";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required, email, requiredIf} from "@vuelidate/validators";

export default {
  name: "Edit",
  components: {
    HeadPanel, Alert
  },
  created() {
    if (!this.$route.params.id)
      return;
    this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255),
        },
        body: {
          required,
          maxLength: maxLength(5000),
        },
        mailFrom: {
          required: requiredIf(function () {
            return this.form.type === 'MAIL';
          }),
          email,
        }
      }
    };
  },
  computed: {
    types() {
      return [
        {'name': 'TELEGRAM', 'label': 'Telegram'},
        {'name': 'MAIL', 'label': 'Mail'},
        {'name': 'SMS', 'label': 'SMS'}
      ];
    },
    statuses() {
      return [
        {'name': 'ACTIVE', 'label': 'Активний'},
        {'name': 'DISABLE', 'label': 'Неактивний'}
      ];
    },
  },
  data() {
    return {
      message: null,
      form: {
        name: null,
        status: 'ACTIVE',
        mailFrom: null,
        mailSubject: null,
        body: null
      }
    }
  },
  methods: {
    fetch: function (uuid) {
      Http
          .get("/message/" + uuid)
          .then((res) => {
            this.message = res.content;
            this.form = this.message;
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      if (this.message)
        this.update();
      else
        this.create();
    },
    create: function () {

      this.form.mailFrom = this.form.mailFrom ? this.form.mailFrom : null;
      this.form.mailSubject = this.form.mailSubject ? this.form.mailSubject : null;

      Http
          .post("/message", this.form)
          .then((res) => {
            this.fetch(res.content)
            this.$router.replace("/message/" + res.content);
            this.$refs.alert.success('Успішно збережено');
          });
    },
    update: function () {

      this.form.mailFrom = this.form.mailFrom ? this.form.mailFrom : null;
      this.form.mailSubject = this.form.mailSubject ? this.form.mailSubject : null;

      Http
          .put("/message/" + this.message.uuid, this.form)
          .then(() => {
            this.$refs.alert.success('Успішно збережено');
          });
    }
  }
};
</script>

<style scoped>

.head-title span {
  font-size: 18px;
}


.invalid-message {
  margin-top: 0.60rem;
  font-size: .875em;
  color: #dc3545;
  float: left;
  margin-left: 10px;
}

textarea.form-control {
  height: 200px;
}

</style>