<template>
  <router-view name="LeftSidebar"/>
  <section class="home">
    <router-view/>
  </section>
</template>

<script>

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

export default {
  name: 'App',
}
</script>

<style>
@import './assets/css/theme.css';
</style>
